<template>
  <div class="mapping-table-wrapper">
    <div class="mapping-table-scroller">
      <div class="mapping-table-content">
        <div
          :class="['table-content-details-wrapper main-source',
                   { 'external-primary-source': selectedFeed !== feedTypes.HUDDLE },
                   { 'full-width': !feeds.length },
          ]"
        >
          <div class="table-content-details">
            <div class="content-details-header">
              <div class="content-details-header-row">
                <img
                  src="/images/icons/betgenius.png"
                  alt="betgenius"
                  v-if="selectedFeed === 'betgenius'"
                >
                <img
                  src="/images/icons/betradar.png"
                  alt="betradara"
                  v-else-if="selectedFeed === 'betradar'"
                >
                <Icon
                  v-if="selectedFeed === feedTypes.HUDDLE"
                  :name="'huddle'"
                />
                <div class="header-label">
                  {{ selectedFeed }}
                </div>
                <div
                  class="header-feed-type"
                  v-if="selectedFeed !== feedTypes.HUDDLE"
                >
                  {{ selectedFeedType }}
                </div>
              </div>
              <div class="content-details-header-row">
                <div class="details-header-row-id">
                  <span>ID</span>
                </div>
                <div class="details-header-label">
                  <span>{{ detailsHeaderLabel }} name</span>
                </div>
              </div>
            </div>
            <div
              class="content-details-row"
              v-for="(row, index) in tableRowsData"
              :key="index"
            >
              <div class="content-details-row-id external-primary-source">
                <div class="row-id">
                  <span :title="selectedFeed === feedTypes.HUDDLE ? row.id : ''">{{ row.id }}</span>
                  <div
                    v-if="selectedFeed === feedTypes.HUDDLE"
                    class="copy-mapping-value-button"
                    @click="copyToClipboard(row.id)"
                  >
                    <Icon name="copy" />
                  </div>
                </div>
              </div>
              <div
                :class="['content-details-row-details', {'competitors-flipped': row?.competitorsFlipped}]"
                :title="row?.competitorsFlipped ? 'Teams flipped' : ''"
              >
                <div class="details-name">
                  {{ row?.competitorsFlipped ? (row.name || 'N/A').split(' v ').reverse().join(' v ') : row.name }}
                </div>
                <div
                  v-if="showMappingInfo(row)"
                  class="details-name-prefix"
                >
                  <span>{{ mappingInfo(row) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="feed-wrapper"
          :class="[
            { 'no-feeds': !feeds.length }
          ]"
        >
          <div class="table-content-details-wrapper">
            <div
              :class="['table-content-details feeds',
                       {
                         'huddle': feed.feed === feedTypes.HUDDLE,
                         'full-width': feeds.length === 1,
                         'half-width': feeds.length === 2,
                       }
              ]"
              v-for="(feed, pfIndex) in feeds"
              :key="pfIndex"
            >
              <div
                class="content-details-header"
                :style="{ top : `${topHeaderPosition}px` }"
              >
                <div class="content-details-header-row">
                  <img
                    src="/images/icons/betgenius.png"
                    alt="betgenius"
                    v-if="feed.feed === 'betgenius'"
                  >
                  <img
                    src="/images/icons/betradar.png"
                    alt="betradar"
                    v-else-if="feed.feed === 'betradar'"
                  >
                  <img
                    src="/images/icons/lsports.png"
                    alt="lsports"
                    v-else-if="feed.feed === 'lsports'"
                  >
                  <Icon
                    v-else
                    :name="'huddle'"
                  />
                  <div class="header-label">
                    {{ feed.feed }}
                  </div>
                  <div
                    class="header-feed-type"
                    v-if="feed.type === 'primary' && feed.feed !== feedTypes.HUDDLE"
                  >
                    Primary feed
                  </div>
                </div>
                <div class="content-details-header-row">
                  <div :class="['details-header-row-id', {'huddle-id': selectedFeed !== feedTypes.HUDDLE}]">
                    <span>ID</span>
                  </div>
                  <div class="details-header-row-name">
                    <span>{{ detailsHeaderLabel }} name</span>
                  </div>
                </div>
              </div>
              <div
                :class="['content-details-row']"
                v-for="(row, index) in tableRowsData"
                :key="index"
              >
                <div :class="['content-details-row-id', {'editable': !mappedFeed(row, feed.feed), 'huddle-id': selectedFeed !== feedTypes.HUDDLE }]">
                  <Tooltip
                    :text="mappingTooltip(mappedFeed(row, feed.feed).mappingType)"
                    v-if="mappedFeed(row, feed.feed)"
                  >
                    <div
                      @click="editMapping(row, feed)"
                      :class="['mapped-id clickable',
                               {'not-exact': mappedFeed(row, feed.feed).mappingType !== 'EXACT_MATCH' }]"
                    >
                      {{ mappedFeed(row, feed.feed).extId || mappedFeed(row, feed.feed).id }}
                    </div>
                  </Tooltip>
                  <div
                    v-if="mappedFeed(row, feed.feed)"
                    class="copy-mapping-value-button"
                    @click="copyToClipboard(mappedFeed(row, feed.feed).extId || mappedFeed(row, feed.feed).id)"
                  >
                    <Icon name="copy" />
                  </div>
                  <div
                    @click="editMapping(row, feed)"
                    class="editable-id clickable"
                    v-else
                  >
                    <Icon :name="'plus'" />
                    <span>Add mapping</span>
                  </div>
                </div>
                <div
                  :class="['content-details-row-details', {'competitors-flipped': mappedFeed(row, feed.feed)?.competitorsFlipped}]"
                  :title="mappedFeed(row, feed.feed)?.competitorsFlipped ? 'Teams flipped' : ''"
                >
                  <div :class="['details-name', {'no-name': !mappedFeed(row, feed.feed)}]">
                    {{ mappedFeed(row, feed.feed) ? displayMappedEventName(row, feed.feed) : '-' }}
                  </div>
                  <div
                    v-if="showMappingInfo(mappedFeed(row, feed.feed))"
                    class="details-name-prefix"
                  >
                    <span>{{ mappingInfo(mappedFeed(row, feed.feed)) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  filter,
  find,
  map,
  has,
  includes,
  cloneDeep,
} from 'lodash';
import { zonedTimeToUtc } from 'date-fns-tz';
import { format } from 'date-fns';
import Icon from '@/components/common/Icon';
import Tooltip from '@/components/common/Tooltip';
import {
  mappingRowData,
  mappingTypes,
  getLabelFromType,
  feedTypes,
  copyToClipboard,
} from '@/services/helpers/mappings-mapper';

const {
  EVENTS_MAPPING,
  COMPETITORS_MAPPING,
  REGIONS_MAPPING,
  COMPETITIONS_MAPPING,
  PLAYERS_MAPPING,
} = mappingTypes;

export default {
  components: {
    Icon,
    Tooltip,
  },
  props: {
    mappingType: {
      type: String,
      required: true,
    },
    topHeaderPosition: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  emits: {
    openPopup: {
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const rowsData = computed(() => store.getters.mappingEntries);
    const primaryFeeds = computed(() => filter(store.getters.mappingDisplayedFeeds, (feed) => includes(store.getters.mappingPrimaryFeeds, feed)));
    const selectedFeed = computed(() => store.getters.mappingSelectedFeed);
    const feeds = computed(() => {
      if (selectedFeed.value !== feedTypes.HUDDLE) return [{ feed: feedTypes.HUDDLE, type: 'primary' }];
      const data = cloneDeep(store.getters.mappingDisplayedFeeds);
      return map(data, (feed) => ({
        feed,
        type: includes(primaryFeeds.value, feed) ? 'primary' : 'secondary',
      }));
    });

    const selectedFeedType = computed(() => (includes(primaryFeeds.value, selectedFeed.value) ? 'Primary feed' : 'Secondary feed'));

    const tableRowsData = computed(() => map(rowsData.value,
      (rowData) => (mappingRowData(
        {
          rowData,
          mappingType: props.mappingType,
          sourceType: selectedFeed.value === feedTypes.HUDDLE ? 'internal' : 'external',
        },
      ))));

    const showMappingInfo = (row) => (
      row?.competitionName && (row?.startsAt || row?.startsAtUtc))
      || row?.playerIndex
      || (props.mappingType === COMPETITIONS_MAPPING && row?.region);

    const detailsHeaderLabel = computed(() => getLabelFromType(props.mappingType));

    const mappedFeed = (row, feed) => {
      if (row.mappings) {
        const exactFeedMapping = find(row.mappings, { feed, mappingType: 'EXACT_MATCH' });
        if (exactFeedMapping) {
          return {
            ...exactFeedMapping,
            competitionName: row.competitionName,
            playerIndex: row.playerIndex,
            competitorsFlipped: exactFeedMapping.competitorsFlipped,
            region: exactFeedMapping?.regionMapping?.extRef,
          };
        }
        const mappings = find(row.mappings, { feed });
        return mappings ? {
          ...find(row.mappings, { feed }),
          competitionName: row.competitionName,
          playerIndex: row.playerIndex,
          region: row?.regionMapping?.extRef,
        } : null;
      }
      if (row.mappedEntity) {
        if (props.mappingType === EVENTS_MAPPING) {
          return {
            id: row.mappedEntity.eventId,
            name: row.mappedEntity.eventName,
            mappingType: row.mappingType,
            competitionName: row.mappedEntity?.competition?.competitionName || row.competitionName,
            competitorsFlipped: row.mappedEntity?.competitorsFlipped,
            startsAtUtc: row.startsAtUtc,
          };
        }
        if (props.mappingType === COMPETITORS_MAPPING) {
          return {
            id: row.mappedEntity.teamId,
            name: row.mappedEntity.name,
            mappingType: row.mappingType,
          };
        }
        if (props.mappingType === REGIONS_MAPPING) {
          return {
            id: row.mappedEntity.regionId,
            name: row.mappedEntity.regionName,
            mappingType: row.mappingType,
          };
        }
        if (props.mappingType === COMPETITIONS_MAPPING) {
          return {
            id: row.mappedEntity.competitionId,
            name: row.mappedEntity.competitionName,
            mappingType: row.mappingType,
            region: row?.region,
          };
        }
        if (props.mappingType === PLAYERS_MAPPING) {
          return {
            id: row.mappedEntity.playerId,
            name: row.mappedEntity.name,
            mappingType: row.mappingType,
            playerIndex: row.mappedEntity.playerIndex,
          };
        }
      }
      return null;
    };

    const mappingInfo = (row) => {
      if (row?.playerIndex) return `Player index • ${row.playerIndex}`;
      if (props.mappingType === mappingTypes.COMPETITIONS_MAPPING && row.region) return row.region;
      const isUnixTime = has(row, 'startsAtUtc');
      const competition = row.competitionMapping?.extRef || row.competitionName;
      const date = isUnixTime ? new Date(Number(row.startsAtUtc)) : row.startsAt;
      const dateFormatted = format(isUnixTime ? date : zonedTimeToUtc(date, 'UTC'), 'MMM d, hh:mm a');
      return `${dateFormatted} • ${competition}`;
    };

    const displayMappedEventName = (row, feed) => {
      const mapped = mappedFeed(row, feed);
      const name = mapped?.extRef ? 'extRef' : 'name';
      if (!mapped[name]) return '-';
      return mapped.competitorsFlipped ? mapped[name].split(' v ').reverse().join(' v ') : mapped[name];
    };

    const copyIdToClipboard = (value) => {
      copyToClipboard(value);
    };

    const editMapping = (row, feed) => {
      const mapping = mappedFeed(row, feed.feed);
      if (mapping?.mappingType === 'EXACT_MATCH') {
        if (selectedFeed.value === feedTypes.HUDDLE) {
          const feedDetails = find(row.mappings, { feed: feed.feed });
          if (feedDetails) copyIdToClipboard(feedDetails.extId);
        } else {
          const { eventId } = row.mappedEntity;
          if (eventId) copyIdToClipboard(eventId);
        }
      }
      emit('openPopup', { row, feed: feed.feed });
    };

    const mappingTooltip = (mappingType) => (mappingType !== 'EXACT_MATCH'
      ? 'Manual approval needed to complete mapping, due to fuzzy matching.'
      : 'Mapping auto-approved, due to high accuracy matching'
    );

    return {
      rowsData,
      selectedFeed,
      feeds,
      tableRowsData,
      detailsHeaderLabel,
      feedTypes,
      copyToClipboard,
      mappedFeed,
      editMapping,
      mappingTooltip,
      mappingInfo,
      showMappingInfo,
      selectedFeedType,
      displayMappedEventName,
    };
  },
};
</script>

<style lang="scss">

  .mapping-table-wrapper {
    background-color: #fff;
    padding: 0 24px;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .mapping-table-scroller {
      height: 100%;
      width: 100%;
    }

    .mapping-table-content {
      display: flex;
      width: 100%;

      .ps {
        width: 100%;
        padding-bottom: 12px;
      }

      .feed-wrapper {
        max-width: 66.66%;
        width: 66.66%;
        overflow-x: auto;
        &.no-feeds {
          display: none;
        }
      }

      .table-content-details-wrapper {
        display: flex;

        &.main-source {
          width: 33.33%;
          background: #fff;
          position: sticky;
          left: 24px;
          z-index: $mappingMainSourceColumn;

          &.full-width {
            width: 100%;
          }
        }

        &.external-primary-source {
          .table-content-details {
            .content-details-row {
              .content-details-row-details {
                width: 70%;
                padding-left: 0;
              }
            }
          }
        }
        .table-content-details {
          display: flex;
          width: 100%;
          flex-direction: column;

          &.feeds {
            min-width: 37%;
            flex-basis: 37%;

            &.full-width {
              min-width: 100%;
              flex-basis: 100%;
            }
            &.half-width {
              min-width: 50%;
              flex-basis: 50%;
            }

            &.huddle {
              width: 100%;
              min-width: 100%;
            }

            .content-details-row {

              & .clickable {
                cursor: pointer;
              }
              .content-details-row-id {
                width: 30%;

                &.huddle-id {
                  width: 45%;
                }
              }

              .content-details-row-details {
                width: 70%;
              }
            }
          }

          .content-details-header {
            display: flex;
            width: 100%;
            flex-direction: column;
            background-color: #FAFAFA;
            position: sticky;
            top: 0;
            z-index: 200;

            .content-details-header-row {
              width: 100%;
              height: 44px;
              display: flex;
              flex-direction: row;
              padding-left: 8px;
              padding-right: 16px;
              box-sizing: border-box;
              align-items: center;
              font-size: 14px;
              color: #6D6D6D;

              img {
                height: 18px;
                width: 18px;
                margin-right: 6px;
              }

              .header-label {
                color: #191414;
                text-transform: capitalize;
              }

              .icon {
                width: 22px;
                height: 11px;
                margin-right: 4px;
              }

              &:first-child {
                border-bottom: 1px solid #F0F0F0;
              }

              .details-header-row-id {
                display: flex;
                align-items: center;
                width: 30%;

                &.huddle-id {
                  width: 45%;
                }
              }

              .details-header-row-name {
                display: flex;
                align-items: center;
                width: 70%;
                color: #6D6D6D;
                padding-left: 8px;
              }

              .details-header-row-name {
                span {
                  &::first-letter {
                    text-transform: uppercase;
                  }
                }
              }

              .header-feed-type {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #175FFF;
                border-radius: 16px;
                padding: 1px 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #191414;
                margin-left: 4px;
                border: 1px solid #A2BFFF;
              }

              .details-header-label, .details-header-input {
                height: 40px;
                display: flex;
                align-items: center;
                color: #6D6D6D;
              }

              .details-header-label {
                margin-right: 12px;
                white-space: nowrap;
              }

              .details-header-label {
                span {
                  &::first-letter {
                    text-transform: uppercase;
                  }
                }
              }

              .details-header-input {
                height: 33px;
                background: #FFFFFF;
                border: 1px solid #DDDDDD;
                box-sizing: border-box;
                border-radius: 4px;
                padding: 0 12px;
                display: flex;
                align-items: center;
                flex-grow: 1;
                width: 100%;

                .icon {
                  width: 16px;
                  height: 16px;
                  margin-right: 0;
                  svg {
                    stroke: #191414;

                    path {
                      stroke-width: 4px;
                    }
                  }
                }

                .close-input-icon {
                  cursor: pointer;
                }

                input {
                  width: 100%;
                  height: 100%;
                  border: 0;
                  outline: none;
                  padding: 0 4px;
                  font-size: 14px;

                  &::placeholder {
                    color: #DDDDDD;
                  }
                }
              }

              .hover-icon {
                width: 33px;
                height: 33px;
                border: 1px solid #DDDDDD;
                box-sizing: border-box;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                cursor: pointer;

                .icon {
                  height: 14px;
                  width: 14px;
                  svg {
                    stroke: #191414;
                    path {
                      stroke-width: 4px;
                    }
                  }
                }
              }
            }
          }

          .content-details-row {
            height: 44px;
            width: 100%;
            display: flex;
            box-shadow: inset 0px -1px 0px #DDDDDD;
            font-size: 14px;

            .content-details-row-id, .content-details-row-details {
              height: 100%;
              display: flex;
            }

            .content-details-row-id {
              align-items: center;
              padding-left: 8px;

              &.external-primary-source {
                width: 30%;
                .row-id {
                  display: flex;
                  width: 100%;
                  align-items: center;
                  span {
                    max-width: 80%;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }
              }

              .tooltip {
                width: auto;
                max-width: 100%;
                .tooltip-text {
                  height: unset;
                  width: 206px;
                  white-space: unset;
                  padding: 8px;
                }
              }

              .mapped-id {
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #00BC57;
                border-radius: 100px;
                padding: 1px 8px;
                border: 1px solid #99E4BB;

                &.not-exact {
                  background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #FFC531;
                  border: 1px solid #FFDC83;
                }
                }

              .editable-id {
                padding: 3.5px 8px;
                box-sizing: border-box;
                border-radius: 4px;
                border: 1px solid #CDCDCD;
                display: flex;
                align-items: center;
                max-width: 95%;
                font-size: 0.875rem;

                span {
                  max-width: 95%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .icon {
                  margin-right: 0.3125rem;

                  svg {
                    stroke: #191414;
                    path {
                      stroke-width: 3px;
                    }
                  }
                }
              }
            }

            .content-details-row-details {
              width: 70%;
              flex-direction: column;
              padding-top: 4px;
              padding-left: 8px;
              justify-content: center;

              &.competitors-flipped {
                background-color: #FFC531;
              }

              .details-name {
                height: 17px;
                line-height: 17px;
                text-overflow: ellipsis;
                max-width: 95%;
                white-space: nowrap;
                overflow: hidden;

                &.no-name {
                  margin-top: 0px;
                }
              }

              .details-name-prefix {
                font-size: 12px;
                color: #6D6D6D;

                span {
                  display: inline-block;
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                };
              }

              .details-time {
                height: 14px;
                line-height: 14px;
                color: #6D6D6D;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .copy-mapping-value-button {
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
      margin-left: 6px;
      cursor: pointer;
      border-radius: 100%;

      .icon {
        width: 100%;
        height: 100%;
        stroke: #000;
      }
    }
  }
</style>
